import "swiper/css";
import { Swiper } from "swiper/react";
import SwiperCore, { Mousewheel, Navigation } from "swiper/core";
import { useRef } from "react";
import isMobile from "is-mobile";
import IconChevronRight from "@/common/icons/chevron-right.svg";
import IconChevronLeft from "@/common/icons/chevron-left.svg";

SwiperCore.use([Navigation, Mousewheel]);

const Slider = ({ text, children, className, slidesPerView = "auto", ...rest }) => {
  const prevRef = useRef();
  const nextRef = useRef();

  return (
    <>
      <div className={className}>{text}</div>
      <div className="relative w-full">
        <button
          type="button"
          className="hidden disabled:hidden lg:flex absolute top-1/2 -left-3 z-10 mr-3 bg-white rounded-full shadow -translate-y-1/2 button-icon"
          aria-label="Previous"
          ref={prevRef}
        >
          <IconChevronLeft className="w-8 h-8" />
        </button>
        <button
          type="button"
          className="hidden disabled:hidden lg:flex absolute top-1/2 -right-6 z-10 bg-white rounded-full shadow -translate-y-1/2 button-icon"
          aria-label="Next"
          ref={nextRef}
        >
          <IconChevronRight className="w-8 h-8" />
        </button>
        <Swiper
          cssMode={isMobile()}
          mousewheel={{ forceToAxis: true }}
          slidesPerView={slidesPerView}
          spaceBetween={30}
          onActiveIndexChange={(slider) => {
            slider.el.classList.toggle("zero", slider.activeIndex === 0);
            slider.el.classList.toggle("last", slider.activeIndex > 0);
          }}
          onInit={(swiper) => {
            if (!swiper.device.ios && !swiper.device.android) {
              // eslint-disable-next-line no-param-reassign
              swiper.params.slidesPerGroup = 1;
              // eslint-disable-next-line no-param-reassign
              swiper.params.navigation.prevEl = prevRef.current;
              // eslint-disable-next-line no-param-reassign
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }
          }}
          className="zero"
          {...rest}
        >
          {children}
        </Swiper>
      </div>
      <style jsx global>{`
        .swiper.zero {
          padding-left: 2rem;
          margin-left: -2rem;
        }

        .swiper.last .swiper-slide:last-child {
          padding-right: 20px;
        }

        .swiper-slide {
          width: auto;
        }
      `}</style>
    </>
  );
};

export default Slider;
